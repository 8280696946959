import intlTelInput from 'intl-tel-input';

const intlPhoneNumber = (input) => {
  return intlTelInput(input, {
    preferredCountries: ['es', 'nl'],
    utilsScript: 'javascript/theme/intl-tel-input.js',
    initialCountry: "es",
    separateDialCode: true
  });
}

const savePrefix = () => {
  const inputPhone = document.querySelector('.intl-phone');

  const iti = intlPhoneNumber(inputPhone);

  if (document.getElementById('prefixNumber')) {
    const prefix = document.querySelector('.iti__selected-dial-code').textContent;
    document.getElementById('prefixNumber').value = prefix;
  } else {
    inputPhone.value = iti.getNumber();
  }
}

export {intlPhoneNumber, savePrefix};
