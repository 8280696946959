export default function scrollSpy() {
  $(document).on('click', '.navbar-scrollspy a', function(event) {
    $(this).parent().find('a').removeClass('active');
    $(this).addClass('active');
  });

  $(window).on('scroll', function() {
    $('.scrollspy-target').each(function() {
        if($(window).scrollTop() + 50 >= $(this).offset().top) {
            var id = $(this).attr('id');
            $('.navbar-scrollspy a').removeClass('active');
            $(".navbar-scrollspy a[href='#" + id + "']").addClass('active');

            $('.scroll-to-active').each(function() {
              if ($(this).find(".active").length > 0) {
                var activeOffset = $(this).find(".active").offset().left;
                $(this).scrollLeft(activeOffset);
              }
            });
        }
    });
  });
}
