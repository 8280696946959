import {createCookie} from './cookiesActions';
import getuserdata from './getUserData';

const typeHouse = () => {
  const button = document.getElementById('confirmHouse');

  const options = document.querySelectorAll('.styled-types input');

  if (!!getuserdata('type_of_house')) {
    const cookie = getuserdata('type_of_house');
    document.getElementById(`${cookie}`).setAttribute('checked', 'checked');
    button.removeAttribute('disabled');
  }

  if (button.hasAttribute('disabled') && ($("#unifamiliar").is(':checked') || $("#multifamiliar").is(':checked'))) {
    button.removeAttribute('disabled');
  }

  options.forEach(option => {
    option.addEventListener('change', (e) => {
      if (button.hasAttribute('disabled')) {
        button.removeAttribute('disabled');
      }
    });
  });

  button.addEventListener('click', () => {
    createCookie('type_of_house', document.querySelector('.styled-types input:checked').value, 1);
  })
}

export default typeHouse;
