import axios from 'axios';

const updateActivity = (publicId) => {
  if (!publicId || publicId.length == 0) { return; }

  let duration = 0;

  window.setInterval(function() {
    if (document.hidden) { console.log('hidden'); return; }

    duration += 5;

    axios.patch(`/activities/${publicId}`, {duration: duration}).then(function(response){}).catch(function(error){
      console.log(error, 'Error while updating user activity');
    });
  }, 5000);
}

export default updateActivity;
