import { fillInAddress } from '../admin/adminAddressAutocomplete';

const industrialParams = () => {
    const addressInput = document.getElementById('address');
    const monthlyBillInput = document.getElementById('installation_monthly_bill');
    const searchParams = new URLSearchParams(window.location.search);
    const address = searchParams.get('address');
    const monthlyBill = searchParams.get('monthly_bill');
    addressInput.value = address;
    monthlyBillInput.value = monthlyBill;
    searchAddress(addressInput);
    assignHiddenFields(searchParams);
}

function searchAddress(addressInput) {
    const options = {
        componentRestrictions: { country: 'es' },
        fields: ["address_components", "geometry"],
        types: ["address"],
        query: addressInput.value
    };

    let map = new google.maps.Map(document.getElementById('map'));
    let service = new google.maps.places.PlacesService(map);
    service.textSearch(options, function (results, status) {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
            getPlaceDetails(results[0].place_id);
        }
    });
}

function getPlaceDetails(place_id) {
    const request = {
      placeId: place_id,
      fields: ['address_components', 'formatted_address']
    };

    let service = new google.maps.places.PlacesService(map);
    service.getDetails(request, function (place, status) {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        const container = document.getElementById('new_installation');
        fillInAddress(place, container);
      }
    });
  }

  const assignHiddenFields = (searchParams) => {
    document.getElementById('lead_source').value = searchParams.get('lead_source');
    document.getElementById('sub_source').value = searchParams.get('sub_source');
    document.getElementById('form_name').value = searchParams.get('form_name');
    document.getElementById('locale').value = searchParams.get('locale');
    document.getElementById('industrial_lead_source').value = searchParams.get('lead_source');
    document.getElementById('industrial_sub_source').value = searchParams.get('sub_source');
    document.getElementById('industrial_form_name').value = searchParams.get('form_name');
  }

export default industrialParams;