const enableButton = () => {
  const inputs = document.querySelectorAll('.upload-file');

  inputs.forEach(input => {
    input.addEventListener('change', (e) => {
      const button = e.currentTarget.parentNode.querySelector('[type=submit]')
      if (!!e.currentTarget.value) {
        button.removeAttribute('disabled');
      } else {
        const attribute = document.createAttribute('disabled');
        attribute.value = 'disabled';
        button.setAttributeNode(attribute);
      }
    });
  })
}

export default enableButton;
