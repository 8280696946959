import getuserdata from './getUserData';

const displayBillAddress = () => {
  const bill = getuserdata('monthly_bill');
  const address = getuserdata('monthly_bill');

  // Display Bill
  let billElem = document.getElementById('monthlyBillConfirm');

  if (billElem) {
    billElem.innerHTML = `${bill}€`;
  }

  // Display Address
  let addressSessionStorage = [];

  if (sessionStorage.getItem('info') != null) {
    addressSessionStorage = (JSON.parse(sessionStorage.getItem('info')).address).split(', ');

    if (addressSessionStorage.length > 1) {
      addressSessionStorage.pop();
    }
  }

  if (address.length == 1) {
      document.getElementById("addressConfirm").innerHTML = `${addressSessionStorage[0][0].toUpperCase() + addressSessionStorage[0].slice(1)}`
    } else {
      let string = '';

      addressSessionStorage.forEach((element, index) => {
        if (index == 0) {
          string = element;
        } else if (isNaN(element)) {
          string = `${string}, ${element}`;
        } else {
          string = `${string} ${element}`;
        }
      });

      let addressConfirmElem = document.getElementById("addressConfirm");

      if (addressConfirmElem) {
        addressConfirmElem.innerHTML = string;
      }
    }

}

export default displayBillAddress;
