const submitFormsRemotely = () => {
  $('form.submit-remotely').each(function() {
    var form = $(this)[0];
    form.addEventListener("submit", function(e) {
      e.preventDefault();
      const data = new FormData(form);
      const action = e.target.action;
      $(form).find('[type="submit"]')[0].innerHTML = `
        <div class="spinner-border text-light" role="status" style='height: 14px; width: 14px;'>
          <span class="visually-hidden">Loading...</span>
        </div>
      `;

      fetch(action, {
        method: 'POST',
        body: data,
      })
      .then(() => {
        $(form).find('[type="submit"]')[0].innerHTML = '✔';
      })
      .catch((err) => {
        console.log(err);
        $(form).find('[type="submit"]')[0].innerHTML = '❌';
      });
    });
  });
}

export default submitFormsRemotely;
