const addInputs = () => {
    const buttons = document.querySelectorAll('#addInputs');

    buttons.forEach(button => {
        button.addEventListener('click', (e) => {
            const containers = document.querySelectorAll(`.${e.currentTarget.getAttribute('data-selector')}`);
            const lastContainer = containers[containers.length - 1];
            const newContainer = lastContainer.cloneNode(true);
            newContainer.querySelector('input').value = '';
            newContainer.querySelector('label').innerText = `${containers[0].querySelector('label').innerText} ${containers.length + 1}`;
            lastContainer.insertAdjacentElement('afterend', newContainer);
        })
    })
}

const removeInputs = () => {
    const buttons = document.querySelectorAll('#removeInputs');

    buttons.forEach(button => {
        button.addEventListener('click', (e) => {
            const containers = document.querySelectorAll(`.${e.currentTarget.getAttribute('data-selector')}`);
            if (containers.length > 1) {
                containers[containers.length - 1].remove();
            }
        })
    })
}

export {addInputs, removeInputs};