import SignaturePad from "signature_pad";

function resizeCanvas(canvas, pad) {
  const data    = pad.toDataURL();
  var ratio     = Math.max(window.devicePixelRatio || 1, 1);
  var width     = closestWidth(canvas);
  canvas.width  = width * ratio;
  canvas.height = canvas.width / 2;
  canvas.getContext("2d").scale(ratio, ratio);
  pad.fromDataURL(data);
}

function closestWidth(element) {
  var parent = $(element).parent()[0];

  if (($(element)[0].offsetWidth == 0 || $(element)[0].offsetWidth == undefined) && parent) {
    return closestWidth(parent);
  } else {
    return $(element)[0].offsetWidth;
  }
}

export function initializeSignaturePad(canvas) {
  canvas.width  = canvas.offsetWidth;
  canvas.height = canvas.width / 2;

  var pad   = new SignaturePad(canvas);
  var input = $(canvas).closest('div').find('input');
  resizeCanvas(canvas, pad);

  $(canvas).closest('div').find('a.clear-signature').click(function() {
    pad.clear();
    input.val('');
  });

  pad.addEventListener("afterUpdateStroke", () => {
    let format = canvas.getAttribute('format');
    $(input).val(pad.toDataURL(format || "image/svg+xml"));
  });

  $(window).on('resize', function(){
    resizeCanvas(canvas, pad);
  });

  $(document).on('click', '[data-bs-toggle="collapse"]', function(){
    resizeCanvas(canvas, pad);
  });
}

export default function signaturePad() {
  $(".signature-wrapper").each(function(i){
    $(this).find("canvas.signature-pad").each(function(){
      initializeSignaturePad(this);
    });
  });
};