import ApplicationController from "./application_controller"
import StimulusReflex from 'stimulus_reflex'

export default class extends ApplicationController {
    static targets = ['display', 'input']

    connect() {
      StimulusReflex.register(this);
      this.originalValue = null;
    }

    edit() {
      this.originalValue = this.displayTarget.textContent.trim();
      this.displayTarget.classList.add('hidden');
      this.inputTarget.classList.remove('hidden');
      this.inputTarget.focus();
      this.autoResize();
    }

    blur () {
      this.save();
    }

    keyup(event) {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        this.inputTarget.blur();
      } else if (event.key === 'Escape') {
        this.cancelEdit();
      }
      this.autoResize();
    }

    cancelEdit() {
      this.inputTarget.value = this.originalValue;
      this.hideInput();
    }

    save () {
      const newValue = this.inputTarget.value.trim();

      if (newValue !== this.originalValue) {
        this.stimulate('RecordReflex#update', newValue);
      } else {
        this.hideInput();
      }
    }

    autoResize() {
      this.inputTarget.style.height = 'auto';
      this.inputTarget.style.height = (this.inputTarget.scrollHeight) + 'px';
    }

    updateSuccess() {
      this.displayTarget.textContent = this.inputTarget.value.trim();
      this.hideInput();
    }

    hideInput() {
      this.displayTarget.classList.remove('hidden');
      this.inputTarget.classList.add('hidden');
    }
}