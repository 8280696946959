import getuserdata from './getUserData';

const behaviorForm = () => {
  const nameInputDiv = document.querySelector('.client_full_name');
  const phoneInputDiv = document.querySelector('.client_phone_number');
  const emailInput = document.getElementById('client_email');
  let addressInputDiv;
  if (document.getElementById('confirm-page') && (getuserdata('missing_number') != undefined || sessionStorage.getItem('address') == null) ) {
    addressInputDiv = document.getElementById('address');
  }

  if (emailInput){
    emailInput.addEventListener('input', () => {
      nameInputDiv.classList.remove('d-none');
      phoneInputDiv.classList.remove('d-none');
      if (document.getElementById('confirm-page') && (getuserdata('missing_number') != undefined || sessionStorage.getItem('address') == null) ) {
        addressInputDiv.classList.remove('d-none');
      }
    });

    emailInput.addEventListener('blur', () => {
      if (emailInput.value == '') {
        nameInputDiv.classList.add('d-none');
        phoneInputDiv.classList.add('d-none');
        if (document.getElementById('confirm-page') && (getuserdata('missing_number') != undefined || sessionStorage.getItem('address') == null) ) {
          addressInputDiv.classList.add('d-none');
        }
      }
    });
  }
}

export default behaviorForm;
