import { Fireworks } from 'fireworks-js'

const fireworksHappiness = () => {
  const container = document.querySelector('.fireworks-container');
  const mainContainer = document.querySelector('.fireworks-class');
  const fireworks = new Fireworks(container, {

  });
  fireworks.start();
}

export default fireworksHappiness;
