import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import toastr from 'toastr';


const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))

toastr.options = {
    "timeOut": "1000"
}