const storage = (address, monthlyBill) => {
  const info = {
    address,
    monthlyBill
  }
  sessionStorage.setItem('info', JSON.stringify(info));
}

const addressStorage = (address) => {
  sessionStorage.setItem('address', JSON.stringify(address));
}

const kitStorage = (kit) => {
  localStorage.setItem('kit', JSON.stringify(kit));
}

export { storage, addressStorage, kitStorage }
