const spinner = () => {
  document.querySelector('body').style.overflowY = 'hidden';

  const form = document.querySelector('.simple_form');
  const container = document.querySelector('.spinner-container');

  form.addEventListener('submit', () => {
    container.classList.remove('d-none');
    container.classList.add('d-flex');
  });
}

export default spinner;
