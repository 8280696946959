import {
  findClosestBuildingInsights,
  getDataLayers
} from "@nora-soderlund/google-maps-solar-api"
import {
  getMonthlyFluxCanvases,
  getRgbCanvas
} from "../base/controllers/getDataLayersCanvas"
import DataLayerOverlay from "../base/interfaces/DataLayerOverlay"
import { GOOGLE_MAPS_API_KEY } from "../base/constants"
import initMap from "./initializations/initMap"
import requestData from "./initializations/requestData"
import {
  initInterface,
  updatePanel,
  updatePanelData,
  setPotentialSegment,
  setFluxMonth,
  setVisibleOverlay
} from "./initializations/initInterface"

export default class SolarPanelsMap {
  initMap = initMap
  requestData = requestData
  initInterface = initInterface
  updatePanel = updatePanel
  updatePanelData = updatePanelData
  setPotentialSegment = setPotentialSegment
  setFluxMonth = setFluxMonth
  setVisibleOverlay = setVisibleOverlay

  solarPanelPolygonReferences = new Map()
  solarPanelPolygons = []

  constructor(apiKey, element) {
    this.apiKey = apiKey
    this.element = element
    element.classList.add("solar-panels")

    this.initMap()
    this.requestData()
    this.initInterface()
  }

  async showInsightsForCoordinate(coordinate) {
    this.formElement.innerHTML = `<h2 class="solar-panels-form-label">Loading data <div class="text-center full-calendar-loader">
        <div class="lds-ring mx-auto"><div></div><div></div><div></div><div></div></div>
      </div></h2>`

    this.buildingInsights = await findClosestBuildingInsights(this.apiKey, {
      location: coordinate,
      requiredQuality: "MEDIUM"
    })

    this.map.moveCamera({
      center: {
        lat: this.buildingInsights.center.latitude,
        lng: this.buildingInsights.center.longitude
      },

      zoom: 20
    })

    const radius = Math.max(
      google.maps.geometry.spherical.computeDistanceBetween(
        {
          lat: this.buildingInsights.boundingBox.ne.latitude,
          lng: this.buildingInsights.boundingBox.ne.longitude
        },
        {
          lat: this.buildingInsights.boundingBox.sw.latitude,
          lng: this.buildingInsights.boundingBox.sw.longitude
        }
      ),
      35
    )

    const location = {
      lat: this.buildingInsights.center.latitude,
      lng: this.buildingInsights.center.longitude
    }

    const coordinateBounds = [
      google.maps.geometry.spherical.computeOffset(location, radius, 5),
      google.maps.geometry.spherical.computeOffset(location, radius, 95),
      google.maps.geometry.spherical.computeOffset(location, radius, 185),
      google.maps.geometry.spherical.computeOffset(location, radius, 275)
    ]

    const dataLayers = await getDataLayers(this.apiKey, {
      location: {
        latitude: this.buildingInsights.center.latitude,
        longitude: this.buildingInsights.center.longitude
      },
      radiusMeters: radius,
      view: "IMAGERY_AND_ALL_FLUX_LAYERS",
      requiredQuality: "MEDIUM"
    })

    const bounds = new google.maps.LatLngBounds()

    coordinateBounds.forEach(coordinate => {
      bounds.extend(coordinate)
    })

    const fluxCanvases = await getMonthlyFluxCanvases(this.apiKey, dataLayers)
    this.fluxCanvasesOverlays = []

    for (var month = 0; month < fluxCanvases.length; month++) {
      const dataLayerOverlayFlux = DataLayerOverlay.create(
        bounds,
        fluxCanvases[month]
      )
      this.fluxCanvasesOverlays.push(dataLayerOverlayFlux)

      dataLayerOverlayFlux.setMap(this.map)
    }

    const rgbCanvas = await getRgbCanvas(GOOGLE_MAPS_API_KEY, dataLayers)
    this.rgbCanvasOverlay = DataLayerOverlay.create(bounds, rgbCanvas)
    this.rgbCanvasOverlay.setMap(this.map)

    this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(
      this.panelElement
    )
    const initialPanelsAmount = parseInt(
      document.getElementById("solar-panels").dataset.solarPanelsAmount
    )
    this.setPotentialSegment(initialPanelsAmount - 4)
    this.updatePanel(initialPanelsAmount - 4)
  }

  showAddressForm() {
    this.mapElement.classList.remove("active")
  }
}

if (window) window.SolarPanelsMap = SolarPanelsMap
