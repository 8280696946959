import ApplicationController from "./application_controller";
import toastr from 'toastr';

export default class extends ApplicationController {
    static targets = ['source']

    copy(event) {
        event.preventDefault(); // Prevent default action if the element is a link
        const text = this.sourceTarget.innerText.trim();

        navigator.clipboard.writeText(text).then(() => {
          // Display Toastr notification
          toastr.success('Copiado al clipboard!');
        }).catch(err => {
          console.error('Failed to copy text: ', err);
          toastr.error('Ups! Algo salió mal');
        });
      }
}