import {createCookie} from './cookiesActions';

const cookieBanner = (cookieBanner) => {
  const button = document.querySelector('#cookie-button');
  button.addEventListener('click', () => {
    createCookie('accepted_cookies', true, 28);
    cookieBanner.style.display = 'none';
  });
}

export default cookieBanner;
