import {createCookie} from './cookiesActions';

const displayMap = () => {
  const mapDiv = document.querySelector('.displayMap');
  const address = mapDiv.getAttribute('data-address');
  const zoom = mapDiv.getAttribute('data-zoom');
  const mapType = mapDiv.getAttribute('data-type');

  showMap(address, Number(zoom), mapType);
}


if (!!document.getElementById('navbar')) {
  const actionName = document.getElementById('navbar').getAttribute('data-action');
}

let map;
let infoWindow;
let service;
let gmarkers = [];

const showMap = (address, zoom, mapType, confirm = false) => {
  map = new google.maps.Map(document.getElementById('map'), {
    tilt: 0,
    zoom: zoom,
    zoomControl: confirm,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    draggable: confirm,
    mapTypeId: mapType,
    mapId: 'MAP_ID'
  });

  findPlace(address);

  initMarker(map);
}

const initMarker = (map) => {
  if (!markerEnabled()) { return; }

  map.addListener('click', (event) => {
    if (gmarkers.length > 0) {
      gmarkers.forEach(marker => marker.setMap(null));
    }
    createMarker(event.latLng);
  });
}

const showMapByCoordinates = (elem, latitude, longitude) => {
  map = new google.maps.Map(elem, {
      tilt: 0,
      zoom: 17,
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      draggable: true,
      mapTypeId: 'satellite',
      mapId: 'MAP_ID'
    });

  let location = {lat: parseFloat(latitude), lng: parseFloat(longitude) };

  createMarker(location);
  map.setCenter(location);
  initMarker(map);
  listenToCoordinatesManualChange();
}

const findPlace = (address) => {
  const request = {
    query: address,
    fields: ['name', 'geometry'],
  };
  service = new google.maps.places.PlacesService(map);
  service.findPlaceFromQuery(request, (results, status) => {
    if (status === google.maps.places.PlacesServiceStatus.OK) {
      // if (actionName != 'confirm') {
        for (let i = 0; i < results.length; i++) {
          createMarker(results[i].geometry.location);
        }
      // }
      map.setCenter(results[0].geometry.location);

      hideError();
    } else {
      showError(status);
    }
  });
}

const createMarker = (place) => {
  const marker = new google.maps.marker.AdvancedMarkerElement({
    map,
    position: place,
  });
  if (markerEnabled()) {
    gmarkers.push(marker);
    saveCoordinates(marker);
  }
}

const listenToCoordinatesManualChange = () => {
  $(document).on('change', '#coordinates-input-latitude, #coordinates-input-longitude', function(){
    var lat = parseFloat($('#coordinates-input-latitude').val());
    var lng = parseFloat($('#coordinates-input-longitude').val());

    if (gmarkers.length > 0) {
      gmarkers.forEach(marker => marker.setMap(null));
    }

    if (lat && lng) {
      createMarker({lat, lng});
    }
  });
}

const markerEnabled = () => {
  return !!document.getElementById('select-roof-page') || !!document.getElementById('confirmMarker');
}

const saveCoordinates = (marker) => {
  createCookie('coordinates', [marker.position.lat, marker.position.lng], 1);

  if (document.getElementById('coordinates-input-latitude') && document.getElementById('coordinates-input-longitude')) {
    document.getElementById('coordinates-input-latitude').value = marker.position.lat;
    document.getElementById('coordinates-input-longitude').value = marker.position.lng;
  }
}

const showError = (message) => {
  if (!document.getElementById('mapModalError')) {
    return;
  }

  document.getElementById('mapModalError').innerHTML = message;
  document.getElementById('mapModalError').classList.remove("hidden");
}

const hideError = () => {
  if (!document.getElementById('mapModalError')) {
    return;
  }

  document.getElementById('mapModalError').innerHTML = '';
  document.getElementById('mapModalError').classList.add("hidden");
}

export {showMap, showMapByCoordinates, displayMap};
