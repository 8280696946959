import * as bootstrap from 'bootstrap';

function formWithSteps() {
  $('.form-with-steps-wrapper').each(function(el) {
    var currentTab = 0;
    var wrapper = this;
    var tabsCount = $(wrapper).find('.step').length;
    showTab(currentTab, wrapper);

    $(wrapper).find('.form-with-steps-next').on('click', function() {
      if (currentTab < tabsCount - 1) {
        showTab(++currentTab, wrapper);
      }
    });

    $(wrapper).find('.submit-on-change').on('change', function() {
      $(wrapper).find('form').submit();
    });

    $(wrapper).find('.form-with-steps-next-on-change').on('change', function() {
      if (currentTab < tabsCount - 1) {
        showTab(++currentTab, wrapper);
      }
    });

    $(wrapper).find('.form-with-steps-back').on('click', function() {
      if (currentTab > 0) {
        showTab(--currentTab, wrapper);
      } else {
        const modalElement = $(wrapper).closest('.modal')[0];
        const modal = new bootstrap.Modal(modalElement);
        $(modalElement).addClass('shown');
        modal.hide();
      }
    });
  });
}

function showTab(n, formWrapper) {
  var steps = $(formWrapper).find('.step');
  steps.addClass('d-none');
  $(steps[n]).removeClass('d-none');

  var indicators = $(formWrapper).find('.step-indicator');
  indicators.removeClass('active');
  $(indicators[n]).addClass('active');
}

export default formWithSteps;
