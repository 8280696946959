const scroll = () => {
  window.addEventListener('scroll', () => {
    const navbar = document.getElementById('navbar');
    const scrollHeight = window.pageYOffset;
    if (scrollHeight > 10) {
      navbar.classList.add('scroll');
    } else {
      navbar.classList.remove('scroll');
    }
  });
}

export default scroll;
