const videoAskHomepage = () => {
  addScriptBody();

  if (window.innerWidth > 450){
    // window.VIDEOASK_EMBED_CONFIG = {
    //   "kind": "widget",
    //   "url": "https://www.videoask.com/f9mypwbhm",
    //   "options": {
    //     "widgetType": "VideoThumbnailExtraLarge",
    //     "text": "Conoce a Fer",
    //     "backgroundColor": "#FDB800",
    //     "position": "bottom-left",
    //     "dismissable": true
    //   }
    // }
  }

  // if (window.innerWidth <= 450){
  //   window.VIDEOASK_EMBED_CONFIG = {
  //     "kind": "widget",
  //     "url": "https://www.videoask.com/f9mypwbhm",
  //     "options": {
  //       "widgetType": "VideoThumbnailSmall",
  //       "text": "Conoce a Fer",
  //       "backgroundColor": "#FDB800",
  //       "position": "bottom-left",
  //       "dismissable": true
  //     }
  //   }
  // }
}

const videoAskResult = () => {

  // if (window.innerWidth > 450){
  //   window.VIDEOASK_EMBED_CONFIG = {
  //     "kind": "widget",
  //     "url": "https://www.videoask.com/fc74ul6b3",
  //     "options": {
  //       "widgetType": "VideoThumbnailExtraLarge",
  //       "text": "Elige tu Kit Solar",
  //       "backgroundColor": "#FDB800",
  //       "position": "bottom-right",
  //       "dismissable": true
  //     }
  //   }
  // }

  if (window.innerWidth <= 1000){
    document.getElementById('finance-button').addEventListener('click', () => {
      addScriptBody();
      window.VIDEOASK_EMBED_CONFIG = {
        "kind": "widget",
        "url": "https://www.videoask.com/fc74ul6b3",
        "options": {
          "widgetType": "VideoThumbnailSmall",
          "text": "Elige tu Kit Solar",
          "backgroundColor": "#FDB800",
          "position": "bottom-left",
          "dismissable": true
        }
      }
    });
  }
}

const videoAskAbout = () => {
  addScriptBody();

  if (window.innerWidth > 450){
    window.VIDEOASK_EMBED_CONFIG = {
      "kind": "widget",
      "url": "https://www.videoask.com/fdp0qe6ry",
      "options": {
        "widgetType": "VideoThumbnailExtraLarge",
        "text": "Sobre Nosotros",
        "backgroundColor": "#FDB800",
        "position": "bottom-right",
        "dismissable": true
      }
    }
  }

  if (window.innerWidth <= 450){
    window.VIDEOASK_EMBED_CONFIG = {
      "kind": "widget",
      "url": "https://www.videoask.com/fdp0qe6ry",
      "options": {
        "widgetType": "VideoThumbnailSmall",
        "text": "Sobre Nosotros",
        "backgroundColor": "#FDB800",
        "position": "bottom-right",
        "dismissable": true
      }
    }
  }
}

const addScriptBody = () => {
  const script = document.createElement('script');
  script.type = 'text/javascript';

  script.src = "https://www.videoask.com/embed/embed.js";
  document.body.appendChild(script);
}

export { videoAskHomepage, videoAskResult, videoAskAbout };
