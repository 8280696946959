const priceFormat = (amount) => {
  return new Intl.NumberFormat(
    'de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true
  })
    .format(amount)
    .replace(/\D00(?=\D*$)/, '');
}

export default priceFormat;
