import { CountUp } from 'countup.js';

const count = () => {
  let energy = new CountUp('energy', 500000);
  let saving = new CountUp('saving', 70000);
  let trees = new CountUp('trees', 3000);

  const elementInView = (el, dividend = 1) => {
    const elementTop = el.getBoundingClientRect().top;
  return (
    elementTop <= (window.innerHeight || document.documentElement.clientHeight) / dividend);
}

  const handleScrollAnimation = () => {
    const container = document.getElementById('counts');
    if (container && elementInView(container, 1.25)) {
      energy.start();
      saving.start();
      trees.start();
      window.removeEventListener('scroll', handleScrollAnimation);
    } else if (!container) {
      window.removeEventListener('scroll', handleScrollAnimation);
    }
  }
  handleScrollAnimation();

  window.addEventListener('scroll', handleScrollAnimation);

}

export default count;
