export default function setFilesToInput(input, data = []) {
  const dt = new ClipboardEvent('').clipboardData || new DataTransfer();

  for (const file of data) {
    dt.items.add(file);
  }

  if (dt.files.length) {
    input.files = dt.files;
  }
}