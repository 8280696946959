const animatePictures = () => {
  const scrollElements = document.querySelectorAll('.js-scroll');


  // const elementOutofView = (el) => {
  //   const elementTop = el.getBoundingClientRect().top;

  //   return (
  //     elementTop > (window.innerHeight || document.documentElement.clientHeight));
  // }

  const displayScrollElement = (element) => {
    element.classList.add('scrolled');
  }

  // const hideScrollElement = (element) => {
  //   element.classList.remove('scrolled');
  // }

  const handleScrollAnimation = () => {
    scrollElements.forEach(el => {
      if (elementInView(el, 1.25)) {
        displayScrollElement(el);
      // } else if (elementOutofView(el)) {
      //   hideScrollElement(el);
      }
    });
  }
  // Called in case there is a reload on the page and the page is already on the element
  handleScrollAnimation();

  // Check if you reach the element to call the function
  window.addEventListener('scroll', () => {
    handleScrollAnimation();
  });
}

const elementInView = (el, dividend = 1) => {
  const elementTop = el.getBoundingClientRect().top;

  return (
    elementTop <= (window.innerHeight || document.documentElement.clientHeight) / dividend);
}

export  {animatePictures, elementInView};
