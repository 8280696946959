import priceFormat from '../custom/priceFormat';
import initPaybackGraph from "../custom/paybackGraph";

const paymentTypeCash = document.getElementById('payment-type-cash');
const paymentTypeSubscription = document.getElementById('payment-type-subscription');
const paymentTypeFinancing = document.getElementById('payment-type-financing');
const bannerPrice = document.getElementById('offer-sticky-price');
const offerPrice = document.getElementById('offer-price-total');
const symbolOffer = document.getElementById('symbol');
const iva = document.getElementById('offer-iva');
const totalPrice = document.getElementById('offer-price-total-2');
const discountLine = document.getElementById('discountLine');
const percentageSpan = document.getElementById('percentage');
const subscriptionDurationDiv = document.getElementById('subscription-duration');
const financingDurationDiv = document.getElementById('financing-duration');
const paybackYearsAmount = 25;
const costSubvencionTextSubscription = document.getElementById('costSubvencionTextSubscription');
const costSubvencionTextUpfront = document.getElementById('costSubvencionTextUpfront');
const financingInterestRate = document.getElementById('financing-interest-rate');
const financingUpfront = document.getElementById('financing-upfront');
const ivaDiv = document.getElementById('iva');

const offerPriceType = async () => {
  let resp = await fetch(`${window.location.pathname}?t=${Date.now()}`, { headers: { accept: 'application/json'}});

  resp = await resp.json();

  let net_price = resp.net_price;
  let gross_price = resp.gross_price;
  let tax_amount = resp.tax_amount
  let savings = resp.savings;
  let percentage = resp.percentage;
  let subscriptionPrice = resp.subscription;
  let financingFee = parseFloat(resp.subscription).toFixed(0);

  if (resp.type == 'subscription') {
    document.getElementById('payment-type-subscription').setAttribute('checked', 'checked');
    document.getElementById('payment-type-cash').removeAttribute('checked', 'checked');

    updatePrices(tax_amount, net_price, gross_price, savings, percentage, subscriptionPrice, financingFee);
  }

  if(resp.type == 'financing') {
    document.getElementById('payment-type-financing').setAttribute('checked', 'checked');
    document.getElementById('payment-type-cash').removeAttribute('checked', 'checked');
    // $('[for="payment-type-subscription"] span').toggleClass('d-none');

    updatePrices(tax_amount, net_price, gross_price, savings, percentage, subscriptionPrice, financingFee);
  }


  initPaybackGraph(calculatePaybackData(gross_price, Math.trunc(savings / 25)))

  function changePaymentType() {
    updatePrices(tax_amount, net_price, gross_price, savings, percentage, subscriptionPrice, financingFee);
  }

  paymentTypeCash.addEventListener('change', changePaymentType);
  if (!!paymentTypeSubscription) {
    paymentTypeSubscription.addEventListener('change', changePaymentType);
  }
  if (!!paymentTypeFinancing) {
    paymentTypeFinancing.addEventListener('change', changePaymentType);
  }
}

const updatePrices = (tax_amount, net_price, gross_price, savings, percentage, subscriptionPrice, financingFee) => {
  if (!!paymentTypeSubscription && paymentTypeSubscription.checked) {
    percentageSpan.innerHTML = 21;
    bannerPrice.innerHTML = `${priceFormat(subscriptionPrice)} €/mes`;
    offerPrice.innerHTML = `${priceFormat(subscriptionPrice)}`;
    symbolOffer.innerHTML = '€/mes';
    iva.innerHTML = `${priceFormat(subscriptionPrice - (subscriptionPrice / 1.21).toFixed(2))}€`;
    totalPrice.innerHTML = `${priceFormat(subscriptionPrice)}€`;
    if (discountLine != null) {
      discountLine.classList.add('d-none');
    }
    initPaybackGraph(calculatePaybackData(subscriptionPrice, Math.trunc(savings / 25)))
    showPdfButton('subscription_pdf');
    subscriptionDurationDiv.classList.remove('d-none');

    if (!!costSubvencionTextSubscription) {
      costSubvencionTextSubscription.classList.remove('d-none');
    }
    if (!!costSubvencionTextSubscription) {
      costSubvencionTextUpfront.classList.add('d-none');
    }

    if (percentage == 10) {
      disclaimer.classList.toggle('d-none');
    }
    if (financingInterestRate != null) {
      financingInterestRate.classList.remove('d-none');
    }
    if (financingUpfront != null) {
      financingUpfront.classList.remove('d-none');
    }
  } else if (!!paymentTypeFinancing && paymentTypeFinancing.checked) {
    bannerPrice.innerHTML = `${priceFormat(financingFee)}€/mes`;
    offerPrice.innerHTML = priceFormat(financingFee);
    totalPrice.innerHTML = `${priceFormat(financingFee)}€/mes `;
    symbolOffer.innerHTML = '€/mes';
    financingDurationDiv.classList.remove('d-none');
    ivaDiv.classList.add('d-none');

    if (financingInterestRate != null) {
      financingInterestRate.classList.remove('d-none');
    }
    if (financingUpfront != null) {
      financingUpfront.classList.remove('d-none');
    }
    if (discountLine != null) {
      discountLine.classList.add('d-none');
    }
  } else {
    bannerPrice.innerHTML = `${priceFormat(gross_price)}€`;
    offerPrice.innerHTML = priceFormat(gross_price);
    symbolOffer.innerHTML = '€';
    iva.innerHTML = `${priceFormat(tax_amount)}€`;
    totalPrice.innerHTML = `${priceFormat(gross_price)}€`;
    percentageSpan.innerHTML = percentage;
    ivaDiv.classList.remove('d-none');

    if (!!costSubvencionTextSubscription) {
      costSubvencionTextSubscription.classList.add('d-none');
    }
    if (!!costSubvencionTextUpfront) {
      costSubvencionTextUpfront.classList.remove('d-none');
    }
    if (financingInterestRate != null) {
      financingInterestRate.classList.add('d-none');
    }
    if (financingUpfront != null) {
      financingUpfront.classList.add('d-none');
    }

    if (!!subscriptionDurationDiv) {
      subscriptionDurationDiv.classList.add('d-none');
    }
    if (!!financingDurationDiv) {
      financingDurationDiv.classList.add('d-none');
    }

    if (percentage == 10) {
      disclaimer.classList.toggle('d-none');
    }
    if (discountLine != null) {
      discountLine.classList.remove('d-none');
    }
    initPaybackGraph(calculatePaybackData(tax_amount + net_price, Math.trunc(savings / 25)));
    showPdfButton('one_time_pdf');
  }
}

const calculatePaybackData = function(price, yearlySavings) {
  const data = Array.apply(null, Array(paybackYearsAmount));
  const labels = Array.apply(null, Array(paybackYearsAmount)).map(function (x, i) { return i + 1; });
  const monthlyBills = JSON.parse(document.getElementById('monthlyBills').dataset.json);

  if (!!paymentTypeSubscription && paymentTypeSubscription.checked) {
    let subscription = data.map(function(val, index) {
      if (index < 20) {
        return price * 12;
      }

      return 0;
    });
    let bill = data.map(function(val, index) {
      return monthlyBills[index] * 12
    });
    let newBill = data.map(function(val, index) {
      return bill[index] - yearlySavings;
    });
    let savings = data.map(function(val, index) {
      return bill[index] - newBill[index] - subscription[index];
    });

    return {
      labels: labels,
      datasets: [
        {
          data: subscription,
          type: 'line',
          fill: true,
          borderColor: 'rgb(255, 255, 255, 0.8)',
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          stack: 'Group 1'
        },
        {
          data: newBill,
          type: 'line',
          fill: true,
          borderColor: 'rgb(255, 51, 0, 0.4)',
          backgroundColor: 'rgba(255, 51, 0, 0.2)',
          stack: 'Group 1'
        },
        {
          data: savings,
          type: 'line',
          fill: true,
          borderColor: 'rgba(28, 187, 63, 0.3)',
          backgroundColor: 'rgba(28, 187, 63, 0.1)',
          stack: 'Group 1'
        }
      ]
    };
  } else {
    let savings = data.map(function(val, index) {
      return yearlySavings * (index + 1) - price
    })

    return {
      labels: labels,
      datasets: [{data: savings}]
    };
  }
}

const showPdfButton = function(id) {
  $('.download-pdf-offer').addClass('d-none');
  $('#' + id).removeClass('d-none');
}

export default offerPriceType;
