const copyNameInstaller = () => {
  const responsibleName = document.getElementById('visit_installer_responsible');
  const installerIntput = document.querySelector('#visit_documents__signer_name');


  responsibleName.addEventListener('keyup', () => {
    installerIntput.value = responsibleName.value;
  })
}

export default copyNameInstaller;
