import { storage, addressStorage } from 'custom/storage';
import {createCookie, deleteCookie} from './cookiesActions';
import getuserdata from './getUserData';
import {removeDiacritics} from './cookiesGenerator';
import { showMap } from '../custom/map';

const autocompleteForm = () => {
  const options = {
    componentRestrictions: { country: 'es' },
    fields: ["address_components", "geometry"],
    types: ["address"],
  };
  let input = document.querySelectorAll('[name=address]');

  input.forEach(inp => {
    let autocomplete = new google.maps.places.Autocomplete(inp, options);

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (getuserdata('coordinates')) {
        deleteCookie('coordinates');
      }
      if (!place.address_components.find( component => component.types[0] == 'street_number')) {
        createCookie('missing_number', true, 28)
      } else if (getuserdata('missing_number')) {
        deleteCookie('missing_number');
      }
      if (document.getElementById('confirm-page') != null) {
        updateInfoStorage();
      }
      if (!!document.getElementById('select-roof-page')) {
        const address = document.getElementById('address').value;
        updateInfoStorage('address');
        showMap(address, 19, 'satellite', true);
      }
      if (!!document.getElementById('map')) {
        const address = document.getElementById('address').value;
        showMap(address, 19, 'satellite', true);
      }
      getAddress(place);
    });
  });
}

const getAddress = (place) => {
  let address = {}
  let address1 = '';
  let city = '';
  let area = '';
  let comunidad = '';
  let postcode = '';
  let country = '';

  createStreetNumberCookie(place.address_components);

  place.address_components.forEach(component => {
    const componentType = component.types[0];
    switch (componentType) {
      case 'street_number': {
        address1 += component.long_name;
        break;
      }

      case 'route': {
        address1 = `${component.short_name} ${address1}`;
        break;
      }

      case 'postal_code': {
        postcode = component.long_name;
        break;
      }

      case 'locality': {
        city = component.long_name;
        break;
      }

      case 'administrative_area_level_1': {
        comunidad = component.long_name;
        break;
      }

      case 'administrative_area_level_2': {
        area = component.long_name;
        break;
      }

      case 'country': {
        country = component.long_name;
        break;
      }
    }
  });

  address = {
    address1,
    postcode,
    area,
    comunidad,
    city,
    country
  };

  addressStorage(address);
  // if (/constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification))) {
    for (const [key, value] of Object.entries(address)) {
      address[key] = removeDiacritics(value);
    }
  // }
  createCookie('addressObject', JSON.stringify(address), 1);
  const inputValue = document.getElementById('address').value;
  createCookie('address', inputValue, 1);

}

const createStreetNumberCookie = (address_components) => {
  for (const component of address_components) {
    if (component.types[0] == 'street_number') {
      return;
    }
  }

  createCookie('streetNumberException', true, 1);
}

const updateInfoStorage = (inputId = 'address') => {
  let info = JSON.parse(sessionStorage.getItem('info'));
  const address = document.getElementById(inputId).value;

  // storage(address, info.monthlyBill);
  createCookie('address', removeDiacritics(address), 1);
}


export {autocompleteForm, getAddress};
