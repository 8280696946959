import priceFormat from '../custom/priceFormat';

const PRICES = {
  5: {
    new: parseInt($("#savings-and-grants [name='monthly_bill']")[0]?.dataset.kitSPrice) * 1.21 || 0
  },
  8: {
    new: parseInt($("#savings-and-grants [name='monthly_bill']")[0]?.dataset.kitMPrice) * 1.21 || 0
  },
  12: {
    new: parseInt($("#savings-and-grants [name='monthly_bill']")[0]?.dataset.kitLPrice) * 1.21 || 0
  },
  16: {
    new: parseInt($("#savings-and-grants [name='monthly_bill']")[0]?.dataset.kitXlPrice) * 1.21 || 0
  }
}


const CAPACITIES = {
  5: 2250,
  8: 3600,
  12: 5400,
  16: 7200
}

let panelsAmount = 5;
let installments = 1;
let totalPrice = 0;
let kitPrice = 0;
let capacity = 0;
let monthlyBill = 50;
let totalPriceDiscounted = 0;
let totalPriceInput = document.getElementById('client_price_suggested');

const savingsAndGrants = () => {
  document.body.style.overflowY = 'hidden';
  $("#savings-and-grants input").on('change', countPrice);
  $(".proposal-button-footer").on('click', showProposal);

  countPrice();
}

const countPrice = () => {
  setMonthlyBill();
  setPanelsAmount();
  setCapacity();
  setInstallments();
  setKitPrice();
  countAnglePrice();
  setOrderSummaryText();
  countEnergyStoragePrice();

  setTotalPrice();

  // setGrants();
  setIRPF();
  setIBI();
  //setIva();

  setTotalPriceDiscounted();

  calculateYearsSavings(25);

  updateInstallmentInput();
}

const setMonthlyBill = () => {
  monthlyBill = parseInt($("#savings-and-grants [name='monthly_bill']")[0].value);
}

const setPanelsAmount = () => {
  if (monthlyBill <= 100) {
    panelsAmount = 5;
  } else if (monthlyBill > 100 && monthlyBill <= 180) {
    panelsAmount = 8;
  } else if (monthlyBill < 200) {
    panelsAmount = 12;
  } else {
    panelsAmount = 16;
  }
}

const setCapacity = () => {
  capacity = CAPACITIES[panelsAmount] / 1000.0;
}

const setKitPrice = () => {
  kitPrice = (PRICES[panelsAmount].new).toFixed(0);
  totalPrice = kitPrice;
  totalPriceInput.value = totalPrice;
}

const setInstallments = () => {
  if ($("#savings-and-grants [name='installments']:checked").length > 0) {
    installments = parseFloat($("#savings-and-grants [name='installments']:checked")[0].value);
  }

  if (installments > 1) {
    $("#savings-and-grants .financing-message").removeClass('d-none');
  } else {
    $("#savings-and-grants .financing-message").addClass('d-none');
  }
}

const countAnglePrice = () => {
  if ($("#savings-and-grants [name='roof_angle']:checked").length > 0 && $("#savings-and-grants [name='roof_angle']:checked")[0].value == '0-15 degrees') {
    kitPrice = parseFloat(kitPrice) + panelsAmount * 60;
    totalPrice = kitPrice;
    totalPriceInput.value = totalPrice;

  }
}

const setOrderSummaryText = () => {
  $("#savings-and-grants .panels-amount")[0].innerHTML = panelsAmount;
  $("#savings-and-grants .total-capacity")[0].innerHTML = capacity;

  if ($("#savings-and-grants [name='solar_panels_color']:checked").length > 0) {
    $("#savings-and-grants .panels-color")[0].innerHTML = $("#savings-and-grants [name='solar_panels_color']:checked")[0].value;
  }

  if ($("#savings-and-grants #store_energy_yes:checked").length > 0) {
    $("#savings-and-grants .battery-price").removeClass('d-none');
  } else {
    $("#savings-and-grants .battery-price").addClass('d-none');
  }

  if ($("#savings-and-grants #inverter_2:checked").length > 0) {
    $("#savings-and-grants .with-battery-and-inverter").removeClass('d-none');
  } else {
    $("#savings-and-grants .with-battery-and-inverter").addClass('d-none');
  }
}

const countEnergyStoragePrice = () => {
  if ($("#savings-and-grants #store_energy_yes:checked").length > 0) {
    totalPrice = parseFloat(kitPrice) + 4500;
    totalPriceInput.value = totalPrice;
  }
}

const setTotalPrice = () => {
  totalPriceDiscounted = totalPrice;

  // $("#savings-and-grants .savings-installation-cost")[0].innerHTML = priceFormat(parseFloat(kitPrice).toFixed(0)) + '€';

  document.querySelectorAll('#savings-and-grants .savings-installation-cost').forEach((elem) => {
    elem.innerHTML = priceFormat(parseFloat(kitPrice).toFixed(0)) + '€';
  });

  $("#savings-and-grants .initial-price .amount")[0].innerHTML = priceFormat((parseFloat(totalPrice) / installments).toFixed(0)) + '€';
}

const setTotalPriceDiscounted = () => {
  $("#savings-and-grants .total-price .amount").each(function (i, elem) {
    elem.innerHTML = priceFormat((totalPriceDiscounted).toFixed(0)) + '€';
  });

  if (totalPriceDiscounted == 0) {
    $('#savings-and-grants .proposal-button-footer.proposal-button').prop('disabled', false);
  } else {
    $('#savings-and-grants .proposal-button-footer.proposal-button').prop('disabled', true);
  }
}

const setGrants = () => {
  let grantsAmount = panelsAmount * 0.4 * 600;
  totalPriceDiscounted -= grantsAmount
  let displayGrantsAmount = priceFormat(grantsAmount.toFixed(0)) + '€';
  $("#savings-and-grants .savings-next-generation .amount")[0].innerHTML = '-' + displayGrantsAmount;
  totalPriceDiscounted -= 200; // Referral reward
}

const setIRPF = () => {
  totalPriceDiscounted -= 1000;
}

const setIBI = () => {
  totalPriceDiscounted -= 300;
}

const setIva = () => {
  let partOfIva = (kitPrice - (kitPrice / 1.21) * 1.1).toFixed(0)
  totalPriceDiscounted -= partOfIva;
}

const calculateGrants = () => {
  // let selectedBatteryCapacity = parseInt($("#savings-and-grants [name='store_energy']:checked")[0].dataset.capacity);
  // let totalBatteryCapacity = selectedBatteryCapacity / 1000.0;

  // let panelGrants = (capacity <= 10 ? 600 : 450) * capacity;
  // let batteryGrants = (totalBatteryCapacity <= 10 ? 490 : 350) * totalBatteryCapacity;

  // return panelGrants + batteryGrants;
}

const calculateYearsSavings = (years) => {
  let monthlyBills = [];
  let savings = 0;
  let kwhMonthlyPanels = (capacity * 1000 * 1500) / 12;

  for (var i = 0; i < years; i++) {
    let kwhMonthlyBill = monthlyBill / 0.25;
    let kwhMonthly = kwhMonthlyBill < kwhMonthlyPanels ? kwhMonthlyBill : kwhMonthlyPanels;

    let yearSavings = (kwhMonthly * 0.6 * 0.25 + kwhMonthly * 0.4 * 0.1) * 12
    let savingsIncrease = i < 15 ? yearSavings : (yearSavings * ((115 - (2 * i)) / 100.00))

    savings += savingsIncrease;
    monthlyBill *= 1.04;
    monthlyBills.push(monthlyBill);
  }

  if ($("#savings-and-grants #store_energy_yes:checked").length > 0) {
    savings *= 1.5;
  }

  $("#savings-and-grants .savings .amount").each(function (i, elem) {
    elem.innerHTML = priceFormat(savings.toFixed(0)) + '€';
  });
}

const showProposal = (e) => {
  e.preventDefault();
  $("#savings-and-grants .generate-proposal-form").addClass('d-none');
  $("#savings-and-grants .price-result").removeClass('d-none');
  $("#savings-and-grants .submit-proposal-form").removeClass('d-none');
  $("#savings-and-grants .proposal-button-footer").addClass('d-none');
  $("#savings-and-grants .submit-proposal").removeClass('d-none');
}

const updateInstallmentInput = () => {
  document.getElementById('client_investment_option').value = document.getElementById('installments1').checked ? 'upfront' : 'financing'
}

export default savingsAndGrants;
