const deselectableRadios = () => {
  $(document).on('click', '[data-deselectable]', function(event){
    event.preventDefault();
    let inputId = $(this).attr('for');
    let radio = $("#" + inputId)[0];
    if(radio.checked) {
      radio.checked = false;
    } else {
      radio.checked = true;
    }
    $(radio).trigger('change');
  });
}

export default deselectableRadios;
