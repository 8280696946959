import { showMap } from '../custom/map';
import { autocompleteForm, getAddress } from '../custom/autocomplete';
import {createCookie} from './cookiesActions';

const addressInput = document.getElementById('address');

export default function selectRoof() {
  createCookie("userid", randomString(32, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'), 1);
  const searchParams = new URLSearchParams(window.location.search);
  const address = searchParams.get('address');
  const monthly_bill = searchParams.get('monthly_bill');
  const lead_source = searchParams.get('lead_source');
  const sub_source = searchParams.get('sub_source');
  const form_name = searchParams.get('form_name');
  const locale = searchParams.get('locale');
  createCookie("monthly_bill", monthly_bill, 1);
  createCookie("lead_source", lead_source, 1);
  createCookie("sub_source", sub_source, 1);
  createCookie("form_name", form_name, 1);
  createCookie("locale", locale, 1);
  addressInput.value = address;
  searchAddress();
  autocompleteForm();
}

function searchAddress() {
  const options = {
    componentRestrictions: { country: 'es' },
    fields: ["address_components", "geometry"],
    types: ["address"],
    query: addressInput.value
  };

  let service = new google.maps.places.PlacesService(map);
  service.textSearch(options, function (results, status) {
    if (status === google.maps.places.PlacesServiceStatus.OK) {
      getPlaceDetails(results[0].place_id);
    }
  });
}
function getPlaceDetails(place_id) {
  const request = {
    placeId: place_id,
    fields: ['address_components', 'formatted_address']
  };

  let service = new google.maps.places.PlacesService(map);
  service.getDetails(request, function (place, status) {
    if (status === google.maps.places.PlacesServiceStatus.OK) {
      showMap(addressInput.value, 19, 'satellite', true);
      getAddress(place);
    }
  });
}

const randomString = (length, chars) => {
  let result = '';
  for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
}

