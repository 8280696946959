export default async function requestData() {
  this.formElement = document.createElement("div")
  this.formElement.className = "solar-panels-form active"
  this.element.append(this.formElement)

  const lat = parseFloat($("#google-solar").data("latitude"))
  const lng = parseFloat($("#google-solar").data("longitude"))

  this.showInsightsForCoordinate({
    latitude: lat,
    longitude: lng
  })
    .then(() => {
      this.formElement.classList.remove("active")
      $("#google-solar-loading").addClass("d-none")
      $("#google-solar").removeClass("d-none")
      $("#full-page-loader").fadeOut("fast")
    })
    .catch(error => {
      console.error(error)
      $("#google-solar-loading").addClass("d-none")
      $("#full-page-loader").fadeOut("fast")
      $("#google-solar-loading-failed").addClass("d-none")
      $(".navbar-scrollspy a:first").remove()
      $(".navbar-scrollspy a:first").addClass("active")
    })
}
