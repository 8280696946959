import latinize from 'latinize';

const result = () => {

// Code for pushing the client cookie data onto the result page and display the correct Solar Kit for them
  var monthly_bill = getuserdata("monthly_bill");
  var cookieId = getuserdata("userid");
  var address = getuserdata("address");
  var kit = getuserdata("kit_suggested");
  var coordinates = getuserdata("coordinates");

  function getuserdata(name) {
      var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
      if (v != null && (v[2] !== null || v[2]!== '')) {
        return v[2];
      }
      return "Not Entered"
  }

  const actionName = document.getElementById('navbar').getAttribute('data-action');
  const controllerName = document.getElementById('navbar').getAttribute('data-controller');
  const today = new Date();
  const page = ['contact', 'how_it_works', 'confirm', 'municipios'];

  document.getElementById("monthly_bill").value = monthly_bill;
  document.getElementById("cookie").value = cookieId;
  document.getElementById('page').value = actionName;
  document.getElementById("time_stamp").value = today.getDate() + "/" + (today.getMonth() + 1) + "/" + today.getFullYear() + "-" + today.getHours() + ":" + today.getMinutes();
  document.getElementById("address").value = address;
  document.getElementById('kit-cookie').value = kit;
  document.getElementById('coordinates').value = coordinates;
}

// const municipio = (address) => {
//   fetch('/municipios', { headers: { accept: 'application/json' } })
//     .then(response => response.json())
//     .then((data) => {
//       if (data.municipio.includes(address.trim())) {
//         document.getElementById('municipio').innerHTML = `<a href="/autoconsumo/instalacion-de-placas-solares-en-${latinize(address.trim().toLowerCase())}" target="_blank">Pincha aqui para saber más sobre las subvenciones de ${address}</a>`
//       }
//   });
// }

export default result;
