import {intlPhoneNumber} from '../custom/intlPhoneNumber';
import {disableButton} from '../common_files/disableButton';
import getuserdata from './getUserData';
import {removeDiacritics, whereCookie} from './cookiesGenerator';
import {createCookie} from './cookiesActions';

const clientValidation = () => {
  const button = document.getElementById('submitLeadForm');
  const inputPhone = document.querySelector('.intl-phone');

  const iti = intlPhoneNumber(inputPhone);

  function serializeData(event, inputPhone, iti) {
    let data = {}
    event.preventDefault();

    disableButton(button);

    const form = document.getElementById('final-offer-form');

    $(form).serializeArray().forEach((value) => {
      data[value.name] = value.value
    });
      data['client[password]'] = '1234567890';
      request(data, 'final-offer-form', inputPhone, iti);
  }

  button.addEventListener('click', function(e) {
    serializeData(e, inputPhone, iti);
  });
}

const request = (data, form_id, inputPhone, iti) => {
  $.ajax({
    url: '/client/validation',
    headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
    type: 'POST',
    crossDomain: true,
    data: data,
    dataType: "json",
    success: function(data) {
      console.log('Data Submitted');
      console.log(data);
      const form = document.getElementById(form_id);

      if (!!document.getElementById('landing-page') && moreValidations()) {
        data.errors = {...data.errors, ...printMoreErrors()};
      }

      if (!iti.isValidNumber() && iti.getValidationError() != 0) {
        data.errors ||= {};
        data.errors.phone_number = "formato inválido"
      }

      if (data.errors == null || Object.keys(data.errors).length === 0) {
        inputPhone.value = iti.getNumber();
        addressCookieCheck();

        $.ajax({
          url: form.action, // Should point to create_lead
          headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
          type: 'POST',
          data: $(form).serialize(),
          dataType: 'script', // Expect a JavaScript response
          success: function() {
            console.log('Form submitted successfully');
          },
          error: function(error) {
            console.error(`Error ${error}`);
            enableButton();
          }
        });
      } else {
        enableButton();
        const spans = form.querySelectorAll('span');
        const containers = document.querySelectorAll('div.mb-0');

        if (!!containers) {
          containers.forEach(container => container.classList.remove('mb-0'));
        }

        if (spans != null) {
          spans.forEach(span => span.remove())
        }

        for (const key of Object.keys(data.errors)) {
          const container = form.querySelector(`.${data.collection}_${key}`);

          container.classList.add('mb-0');
          container.insertAdjacentHTML('afterend', `<span class='errors'>${data.errors[key]}</span>`);
        }
      }
    },
    error: function(error) {
      console.error(`Error ${error}`);
    }
  })
}

const moreValidations = () => {
  return !document.getElementById('address').value || !document.getElementById('client_monthly_bill').value
}

const printMoreErrors = () => {
  const errors = new Object();
  const error = 'no puede estar en blanco';

  if (!getuserdata('addressObject')) {
    errors.address = 'seleccione una dirección de la lista'
  }

  if (!document.getElementById('address').value) {
    errors.address = error;
  }

  if (!document.getElementById('client_monthly_bill').value) {
    errors.monthly_bill = error
  }

  return errors;
}

const addressCookieCheck = () => {
  let address = getuserdata('addressObject');
  if (!address) {
    address = sessionStorage.getItem('address');

    // for (const [key, value] of Object.entries(address)) {
    //   address[key] = removeDiacritics(value);
    // }
    createCookie('addressObject', address, 1);
  }

  return;
}

const enableButton = () => {
  const button = document.getElementById('submitLeadForm');

  button.classList.remove('isDisabled');
  button.innerHTML = 'Continuar';
}

export default clientValidation;
