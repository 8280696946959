const videoSelection = () => {
  const containers = document.querySelectorAll('.content-section-item');
  const video = ['https://www.videoask.com/f3a1znyqe','https://www.videoask.com/fqgaxgdb8', 'https://www.videoask.com/fmzjjbx00', 'https://www.videoask.com/f3cgpnar3'];
  const content = [
    'Un mundo impulsando energías renovables donde todos tengamos la posibilidad de participar.',
    'Para ser particípes del cambio, llevamos la energía solar a cada hogar simplificando cada proceso.',
    'Total transparencia y confianza en toda nuestra comunicación contigo a lo largo de nuestra relación solar.',
    'En nuestra oficina, realizamos pequeñas acciones para tener un buen ambiente sostenible.'
  ]

  const closeContainer = (containers) => {
    containers.forEach(container => {
      if (container.classList.contains('select')) {
        container.classList.remove('select');
        container.querySelector('.title').nextElementSibling.remove();
        container.querySelector('i').classList.add('fa-plus');
        container.querySelector('i').classList.remove('fa-minus');
      }
    });
  };

  containers.forEach(container => {
    container.addEventListener('click', () => {

      if (!container.classList.contains('select')) {
        closeContainer(containers);
        const contNum = container.getAttribute('data-info');
        container.classList.add('select');
        container.querySelector('i').classList.remove('fa-plus');
        container.querySelector('i').classList.add('fa-minus');
        if (window.innerWidth > 768) {
          container.insertAdjacentHTML('beforeend',
            `<div class='space-container animation'>${content[contNum]}</div>`);
          const iframe = document.querySelector('iframe');
          iframe.src = video[contNum];
          if (contNum != 0) {
            iframe.style.width = '400px';
          } else {
            iframe.style.width = '100%';
          }
        } else {
          container.insertAdjacentHTML('beforeend',
            `<div class='space-container animation'>${content[contNum]}
              <div class='mt-4'>
                <div>
                  <div>
                    <div class='bg-dark video-container-about'>
                      <iframe id="frame-referral" allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;" src="${video[contNum]}" class="videoask-embed__Iframe-z0tlzz-1 fXdnXg video-iframe"></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>`);
        }
      }
    });
  });
}

export default videoSelection;
