import getuserdata from './getUserData';
import numeral from 'numeral';
import 'numeral/locales/es';
import 'numeral/locales/en-gb';

export default function fillElementsFromCookies() {
  const monthlyBillField = document.getElementById('monthly-bill');
  const dataMonthlyBill  = getuserdata('monthly_bill');

  const dataPreselectedKit = localStorage.getItem('kit') && JSON.parse(localStorage.getItem('kit'));

  if (monthlyBillField && dataMonthlyBill) {
    monthlyBillField.innerHTML = dataMonthlyBill;
  }
}
