const displayData = () => {

  const data = JSON.parse(sessionStorage.getItem('info'));

  if (!!data) {
    display(data);
  }
}

const display = (data) => {
  const addressInput = document.querySelectorAll('[itemprop=address]');
  const monthlyBillInput = document.querySelectorAll('[name=monthly_bill]');

  if (!!data.address) {
    addressInput.forEach(input => input.value = data.address);
  }

  if (!!data.monthlyBill) {
    monthlyBillInput.forEach(input => input.value = data.monthlyBill);
  }
}

export default displayData;
