import ClipboardJS from 'clipboard';
import { Tooltip } from 'bootstrap';

const initCopyToClipboardButtons = (id) => {
  const setTooltip = (tooltip) => {
    tooltip.show();
  }

  const hideTooltip = (tooltip) => {
    setTimeout(() => {
      tooltip.hide();
    }, 2000);
  }

  var clipboard = new ClipboardJS("[data-clipboard-target]");

  clipboard.on('success', function(e) {
    let tooltip = new Tooltip(e.trigger);
    setTooltip(tooltip);
    hideTooltip(tooltip);

    e.clearSelection();
  });
}

export default initCopyToClipboardButtons;
