import * as bootstrap from 'bootstrap';

const openModalWhenScrollTo = (modalId, elemId) => {
  var targetElement = document.getElementById(elemId);
  var modalElement = document.getElementById(modalId);

  if (!targetElement || !modalElement) { return; }

  $(window).scroll(function() {
    if (targetElement && !$(modalElement).hasClass('shown') && $(window).scrollTop() >= $(targetElement).offset().top) {
      const modal = new bootstrap.Modal(modalElement);
      modal.show();
      $(modalElement).addClass('shown');
    }
  });
}

export default openModalWhenScrollTo;
