import axios from 'axios';
import setFilesToInput from './setFilesToInput';
import Compress from 'compress.js';

const imageCompressor = () => {
  const compress = new Compress();

  document.querySelectorAll('.optimize-images').forEach(input => {
    input.addEventListener('change', (e) => {
      showLoader(e.target);
      let maxSize = $(e.target).attr('data-max-size') || 0.5;
      let quality = $(e.target).attr('data-quality') || 0.5;
      let maxWidth = $(e.target).attr('data-max-width') || 1920;
      let maxHeight = $(e.target).attr('data-max-height') || 1920;

      const files = [...e.target.files]
      compress.compress(files, {
        size: maxSize, // the max size in MB, defaults to 2MB
        quality: quality, // the quality of the image, max is 1,
        maxWidth: maxWidth, // the max width of the output image, defaults to 1920px
        maxHeight: maxHeight, // the max height of the output image, defaults to 1920px
        resize: true, // defaults to true, set false if you do not want to resize the image width and height
        rotate: false
      }).then((data) => {
        data.forEach(function(elem, index) {
          var blob = Compress.convertBase64ToFile(elem.data, elem.ext);
          data[index] = new File([blob], elem.alt);
        });
        setFilesToInput(e.target, data);

        hideLoader(e.target);
      })
    })
  })
}

function showLoader(input) {
  $(input).closest('.optimize-images-wrapper').find('.optimize-images-loader').removeClass('d-none');
}

function hideLoader(input) {
  $(input).closest('.optimize-images-wrapper').find('.optimize-images-loader').addClass('d-none');
}

export default imageCompressor;
