import { initializeSignaturePad } from './signaturePad';

export function cloneElement() {
  $(document).on('click', '.clone-element', function(e){
    let target = $($(this).data("target")).last();
    let clone = target.clone();
    target.after(clone);
    clone.find(".clear-on-copy").val("");
    clone.find(".remove-element").removeClass("d-none");
    initializeSignaturePad(clone.find("canvas")[0]);
  });
}

export function removeElement() {
  $(document).on('click', '.remove-element', function(e){
    let target = $(this).closest($(this).data("target"));
    target.remove();
  });
}
