import Chart from 'chart.js/auto';
import { externalTooltipHandler } from 'theme/chart.js'

let chart;

const initPaybackGraph = (data) => {
  const canvas = document.getElementById('paybackGraph')
  const ctx    = canvas.getContext('2d')
  var gradient = ctx.createLinearGradient(0, 0, 0, 400)
  gradient.addColorStop(0.9, 'rgba(28, 187, 63, 0.09)')
  gradient.addColorStop(0, 'rgba(28, 187, 63, 0.48)')
  const xText = canvas.getAttribute('data-x-text')
  const yText = canvas.getAttribute('data-y-text')
  const yStack = canvas.getAttribute('data-y-stack')
  let tooltipText = canvas.getAttribute('data-tooltip-text')
  if (data.datasets.length > 1) {
    tooltipText = JSON.parse(canvas.getAttribute('data-tooltip-text-multiple'))
  }
  const config = {
    maintainAspectRatio: false,
    type: 'bar',
    data: data,
    options: {
      backgroundColor: gradient,
      scales: {
        y: {
          stacked: true,
          title: {
            text: yText,
            display: true,
            font: {
              weight: 600
            },
          }
        },
        x: {
          title: {
            text: xText,
            display: true,
            font: {
              weight: 600
            },
          }
        }
      },
      plugins: {
        tooltip: {
          // Disable the on-canvas tooltip
          enabled: false,
          external: function(context) {
            externalTooltipHandler(context, tooltipText, '€')
          }
        }
      }
    }
  }

  if (chart) {
    chart.destroy();
  }

  chart = new Chart(canvas, config)
}

export default initPaybackGraph;
