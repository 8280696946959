import { generate } from "@pdfme/generator";
import Rails from '@rails/ujs';

const shortContractPdf = async () => {

  let resp = await fetch(`${window.location.pathname}?t=${Date.now()}`, { headers: {accept: 'application/json'}})

  resp = await resp.json();

  document.getElementById('short-contract').addEventListener('click', () => {
    generatePdf(resp);
  });
}

const generatePdf = async (resp) => {
  const template = {
    "schemas": [
      {
        "client_full_name": {
          "type": "text",
          "position": {
            "x": 76.79,
            "y": 51.06
          },
          "width": 57.22,
          "height": 7,
          "alignment": "left",
          "fontSize": 9,
          "characterSpacing": 0,
          "lineHeight": 1,
          "fontColor": "#060e42"
        },
        "client_email": {
          "type": "text",
          "position": {
            "x": 76.79,
            "y": 57.67
          },
          "width": 56.7,
          "height": 7,
          "alignment": "left",
          "fontSize": 9,
          "characterSpacing": 0,
          "lineHeight": 1,
          "fontColor": "#060e42"
        },
        "client_phone_number": {
          "type": "text",
          "position": {
            "x": 76.79,
            "y": 64.55
          },
          "width": 35,
          "height": 7,
          "alignment": "left",
          "fontSize": 9,
          "characterSpacing": 0,
          "lineHeight": 1,
          "fontColor": "#060e42"
        },
        "location": {
          "type": "text",
          "position": {
            "x": 141.02,
            "y": 51.06
          },
          "width": 46.11,
          "height": 13.08,
          "alignment": "left",
          "fontSize": 9,
          "characterSpacing": 0,
          "lineHeight": 1,
          "fontColor": "#060e42"
        },
        "sp_brand": {
          "type": "text",
          "position": {
            "x": 141.02,
            "y": 64.55
          },
          "width": 46.1,
          "height": 7,
          "alignment": "left",
          "fontSize": 9,
          "characterSpacing": 0,
          "lineHeight": 1,
          "fontColor": "#060e42"
        },
        "date": {
          "type": "text",
          "position": {
            "x": 179.91,
            "y": 14.56
          },
          "width": 18.86,
          "height": 7,
          "alignment": "left",
          "fontSize": 9,
          "characterSpacing": 0,
          "lineHeight": 1,
          "fontColor": "#060e42"
        }
      }
    ],
    "basePdf": await basePdf()
  };

  const inputs = [
    {
      "client_full_name": resp.client.name,
      "client_email": resp.client.email,
      "client_phone_number": resp.client.phone_number,
      "location": resp.installation.address,
      "sp_brand": resp.panel_brand,
      "date": resp.today
    }
  ];

  if (resp.installation_payment_method == 'subscription') {
    template['schemas'][0].subscription_price = {
          "type": "text",
          "position": {
            "x": 80.29,
            "y": 118.15
          },
          "width": 49.28,
          "height": 17.05,
          "alignment": "center",
          "fontSize": 50,
          "characterSpacing": 0,
          "lineHeight": 1,
          "fontColor": "#060e42"
        }
    template['schemas'][0].acquisition_text = {
          "type": "text",
          "position": {
            "x": 71.02,
            "y": 151.07
          },
          "width": 67.81,
          "height": 5.67,
          "alignment": "center",
          "fontSize": 9,
          "characterSpacing": 0,
          "lineHeight": 1,
          "fontColor": "#060e42"
        }
    inputs[0].subscription_price = `${resp.subscription_price}€`
    inputs[0].acquisition_text = `${document.querySelector('div[data-locale]').getAttribute('data-locale') === 'es' ? 'El valor de adquisición es de' : 'The acquisition value is'} ${resp.acquisition_price}€`
  } else {
    template['schemas'][0].installation_price = {
        "type": "text",
        "position": {
          "x": 53.7,
          "y": 118.15
        },
        "width": 102.45,
        "height": 17.05,
        "alignment": "center",
        "fontSize": 50,
        "characterSpacing": 0,
        "lineHeight": 1,
        "fontColor": "#060e42"
      }
    inputs[0].installation_price = `${resp.acquisition_price}€`
  }

  const pdf = await generate({ template, inputs });

  // Browser
  const blob = new Blob([pdf.buffer], { type: 'application/pdf' });
  window.open(URL.createObjectURL(blob));
}

const basePdf = async () => {
  const data = await fetch(window.pdfAssetPath);
  const blobData = await data.blob();
  const newBlobData = new Blob([blobData], { type: 'application/pdf' });

  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(newBlobData);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    }
  })
}

export default shortContractPdf;
