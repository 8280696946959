const getuserdata = (name) => {
  const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');

  false && (v[2] !== null || v[2] !== '')
  if (v !== undefined && v !== null && (v[2] !== null || v[2] !== '')) {
    return v[2];
  }
}

export default getuserdata;
