const googleReviews = () => {
  const left = document.getElementById('reviewLeft');
  const right = document.getElementById('reviewRight');

  const leftResponsive = document.getElementById('reviewLeftResponsive');
  const rigthResponsive = document.getElementById('reviewRightResponsive');
  let num = 1;
  const json = {
    reviews: [{
        id: 1,
        name: 'Lola Guerrero',
        // city: ,
        date: 'February 2021' ,
        content: '"Estoy muy contenta de haber escogido al equipo de Solarmente para mi Instalacion de placas solares , eficientes, buena gente y resolutivos con los documentos oficiales y si hay problemas q puedan surgir . Muchas gracias y los recomiendo cien x cien."',
        image_url: 'https://images.unsplash.com/photo-1512813498716-3e640fed3f39?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=966&q=80'
      },
      {
      id: 2,
      name: 'Juliano Bahía',
      // city: ,
      date: 'Noviembre 2020' ,
      content: '"Atención al cliente estupenda, precios competitivos y calidad de productos fenomenal. Tenemos todo el sistema en marcha por más de 2 meses y se nota un ahorro considerable en la factura de electricidad, aproximadamente 50% menos que las anteriores."',
      image_url: 'https://images.unsplash.com/photo-1543486958-d783bfbf7f8e?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2250&q=80'
      },
      {
        id: 3,
        name: 'Alberto Pacheco',
        // city: ,
        date: 'Abril 2020' ,
        content: '"Tienen un excelente servicio de atención al cliente, la instalación fue muy rápida y fácil, gente muy profesional, y puedo notar que estoy ahorrando bastante dinero a final de mes. Muy recomendable, no suelo poner recomendaciones en google, pero con esta gente quede tan contento que tuve que hacerlo."',
        image_url: 'https://images.unsplash.com/photo-1600657644140-aa5b5e003829?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2250&q=80'
      }
    ]
  }

  displayReview(json.reviews[0]);

  left.addEventListener('click', () => {
    num = leftMove(num, json);
  });

  leftResponsive.addEventListener('click', () => {
    num = leftMove(num, json);
  });

  right.addEventListener('click', () => {
    num = rightMove(num, json);
  });

  rigthResponsive.addEventListener('click', () => {
    num = rightMove(num, json);
  });
}

const rightMove = (num, json) => {
  if (num == json.reviews.length) {
    num = 1;
  } else {
    num++;
  }
  displayReview(json.reviews.find(element => element.id == num));
  return num;
}

const leftMove = (num, json) => {
  if (num == 1) {
      num = json.reviews.length;
  } else {
    num--;
  }
  displayReview(json.reviews.find(element => element.id == num));
  return num;
}

const displayReview = (review) => {
  document.getElementById('description-review').innerHTML = review.content;
  document.querySelector('.image-google').src = review.image_url;
  document.getElementById('name-google').innerHTML = review.name;
  document.getElementById('date-google').innerHTML = review.date;
}

export default googleReviews;
