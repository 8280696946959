import priceFormat from "../../../priceFormat";
export function initInterface() {
  this.panelEnergyConicInnerElement = document.querySelector('.annual-energy');

  this.panelAmountInputElement = document.querySelector('.solar-panels-panel-amount-input');

  this.systemEnergyElement = document.querySelector('.system-energy');

  this.panelsCapacity = parseInt(this.systemEnergyElement.getAttribute('data-panel-capacity'));

  $(this.panelAmountInputElement).on('change paste keyup input', () => {
    this.setPotentialSegment(parseInt(this.panelAmountInputElement.value) - 4);
  });

  this.fluxCheckBox = document.querySelector('#showFlux');

  $(this.fluxCheckBox).change(() => {
    if (this.fluxCheckBox.checked) {
      this.setVisibleOverlay(this.fluxCanvasesOverlays, 0);
      $(fluxSliderContainer).find('input').removeAttr('disabled');
    } else {
      this.setVisibleOverlay(this.rgbCanvasOverlay);
      $(fluxSliderContainer).find('input').attr('disabled', 'true');
    }
  })

  // Adding FLUX layers control

  const fluxSliderContainer = document.querySelector('.solar-panels-flux-slider-container');

  this.fluxSliderElement = document.querySelector('.solar-panels-flux-slider');

  this.fluxSliderElement.addEventListener("input", () => {
    this.setFluxMonth(parseInt(this.fluxSliderElement.value));
  })
}

export function updatePanel(current) {
  this.panelAmountInputElement.value = current + 4;
  this.panelAmountInputElement.max = (
    this.buildingInsights.solarPotential.solarPanelConfigs.length - 1 + 4
  ).toString()
}

export function updatePanelData(segmentIndex) {
  const solarPanelConfig = this.buildingInsights.solarPotential
    .solarPanelConfigs[segmentIndex]
  const lastSolarPanelConfig = this.buildingInsights.solarPotential
    .solarPanelConfigs[
    this.buildingInsights.solarPotential.solarPanelConfigs.length - 1
  ]

  this.panelEnergyConicInnerElement.innerText =
    priceFormat(Math.round(solarPanelConfig.yearlyEnergyDcKwh).toString())
    if (!!panelsCount.value) {
      this.systemEnergyElement.innerText = priceFormat(((panelsCount.value * this.panelsCapacity) / 1000).toFixed(2).toString())
    }

}

export function setPotentialSegment(configIndex) {
  this.updatePanelData(configIndex)

  this.solarPanelPolygons.forEach(polygon => polygon.setMap(null))
  this.solarPanelPolygons = []

  const solarPanelConfig = this.buildingInsights.solarPotential
    .solarPanelConfigs[configIndex]

  let panelsCount = 0
  solarPanelConfig.roofSegmentSummaries.forEach(roofSegmentSummary => {
    this.buildingInsights.solarPotential.solarPanels
      .filter(
        solarPanel =>
          solarPanel.segmentIndex === roofSegmentSummary.segmentIndex
      )
      .slice(
        0,
        Math.min(
          solarPanelConfig.panelsCount - panelsCount,
          roofSegmentSummary.panelsCount
        )
      )
      .forEach(solarPanel => {
        let height = this.buildingInsights.solarPotential.panelHeightMeters / 2
        let width = this.buildingInsights.solarPotential.panelWidthMeters / 2

        if (solarPanel.orientation === "LANDSCAPE") {
          const previousHeight = height

          height = width
          width = previousHeight
        }

        const angle = roofSegmentSummary.azimuthDegrees

        if (!this.solarPanelPolygonReferences.has(solarPanel)) {
          const center = {
            lat: solarPanel.center.latitude,
            lng: solarPanel.center.longitude
          }

          const top = google.maps.geometry.spherical.computeOffset(
            center,
            height,
            angle + 0
          )
          const right = google.maps.geometry.spherical.computeOffset(
            center,
            width,
            angle + 90
          )
          const left = google.maps.geometry.spherical.computeOffset(
            center,
            width,
            angle + 270
          )

          const topRight = google.maps.geometry.spherical.computeOffset(
            top,
            width,
            angle + 90
          )
          const bottomRight = google.maps.geometry.spherical.computeOffset(
            right,
            height,
            angle + 180
          )
          const bottomLeft = google.maps.geometry.spherical.computeOffset(
            left,
            height,
            angle + 180
          )
          const topLeft = google.maps.geometry.spherical.computeOffset(
            left,
            height,
            angle + 0
          )

          this.solarPanelPolygonReferences.set(
            solarPanel,
            new google.maps.Polygon({
              map: this.map,

              fillColor: "#000000",
              fillOpacity: 1,

              strokeWeight: 1,
              strokeColor: "#FFFFFF",
              strokeOpacity: 0.7,

              geodesic: false,

              paths: [topRight, bottomRight, bottomLeft, topLeft]
            })
          )
        }

        const polygon = this.solarPanelPolygonReferences.get(solarPanel)
        polygon.setMap(this.map)

        this.solarPanelPolygons.push(polygon)
      })

    panelsCount += roofSegmentSummary.panelsCount
  })
}

export function setFluxMonth(configIndex) {
  this.fluxCanvasesOverlays.forEach(function(layer, index) {
    if (index != configIndex) {
      $(layer.element).addClass("d-none")
    } else {
      $(layer.element).removeClass("d-none")
    }
  })
}

export function setVisibleOverlay(overlay, layerNumber) {
  this.fluxCanvasesOverlays.forEach(function(layer) {
    $(layer.element).addClass("d-none")
  })
  $(this.rgbCanvasOverlay.element).addClass("d-none")

  if (layerNumber !== undefined) {
    $(overlay[layerNumber].element).removeClass("d-none")
  } else if (overlay !== undefined) {
    $(overlay.element).removeClass("d-none")
  }
}

function createRadioButton(name) {
  const radioContainer = document.createElement("div")
  radioContainer.className = "d-flex"

  const radioButton = document.createElement("input")
  radioButton.className = `solar-panels-radio-${name} me-3`
  radioButton.id = `solar-panels-radio-${name}`
  radioButton.type = "radio"
  radioButton.value = name
  radioButton.name = "layer"
  radioContainer.append(radioButton)

  const radioText = document.createElement("label")
  radioText.setAttribute("for", `solar-panels-radio-${name}`)
  radioText.innerHTML = name
  radioContainer.append(radioText)

  return [radioContainer, radioButton]
}
