import {savePrefix} from '../custom/intlPhoneNumber';

const disableButton = (button) => {
  button.classList.add('isDisabled');
  button.innerHTML = `
    <div class="spinner-border text-light" role="status" style='height: 14px; width: 14px;'>
      <span class="visually-hidden">Loading...</span>
    </div>
  `
}

const selectButton = () => {
  const button = document.querySelector('.disabling');

  button.addEventListener('click', (e) => {
    e.preventDefault();
    disableButton(e.currentTarget);
    savePrefix();
    document.querySelector('form').submit();
  });
}

export {disableButton, selectButton};
