import flatpickr from 'flatpickr';
import { Spanish } from "flatpickr/dist/l10n/es.js"

const pickDateSeveralInputs = () => {
  const dateInputs = document.querySelectorAll('.datepicker');

  dateInputs.forEach(input => {
    const mode = input.getAttribute('data-mode');
    flatpickr(input, {
      locale: Spanish,
      dateFormat: "d/m/Y",
      mode: mode || 'single',
      allowInput: true,
      onClose: function(selectedDates, dataStr, instance) {
        if (instance.input.classList.contains('auto-submit-date')) {
          instance.input.form.submit();
        }
      }
    });
  })
}

const pickTimeSeveralInputs = () => {
  const timeInputs = document.querySelectorAll('.timepicker');

  timeInputs.forEach(input => {
    flatpickr(input, {
      locale: Spanish,
      dateFormat: "H:i",
      enableTime: true,
      noCalendar: true,
      time_24hr: true,
      minTime: "07:00",
      maxTime: "20:00",
      mode: 'single',
      defaultDate: input.value || "10:00",
      allowInput: true
    });
  })
}

export { pickDateSeveralInputs, pickTimeSeveralInputs };
