const inputNumber = () => {
  $('.input-number-wrapper').each(function() {
    var input = $(this).find('input');
    $(this).find('.input-number-minus').on('click', function(e){
      var min = parseInt($(input).attr('min'));
      var oldVal = $(input).val();
      if (parseInt(oldVal) - 1 >= min) {
        $(input).val(parseInt(oldVal) - 1);
        $(input).trigger('input');
      }
    });

    $(this).find('.input-number-plus').on('click', function(e){
      var max = parseInt($(input).attr('max'));
      var oldVal = $(input).val();
      if (parseInt(oldVal) + 1 <= max) {
        $(input).val(parseInt(oldVal) + 1);
        $(input).trigger('input');
      }
    });
  });
}

export default inputNumber;
