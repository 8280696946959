//import Tiff from "tiff.js";
import { getGeoTiff } from "@nora-soderlund/google-maps-solar-api"

//@ts-ignore
import * as GeoTIFF from "geotiff"

const expectedSize = 2000
const scale = 1
const size = expectedSize * scale

export async function getDataLayerRgbCanvas(apiKey, dataLayers) {
  const tiffImageBuffer = await getGeoTiff(apiKey, dataLayers.rgbUrl)

  const tiff = await GeoTIFF.fromArrayBuffer(tiffImageBuffer)
  const tiffImage = await tiff.getImage()
  const tiffData = await tiffImage.readRasters()

  const canvas = document.createElement("canvas")

  canvas.width = tiffData.width
  canvas.height = tiffData.height

  const context = canvas.getContext("2d")

  for (let row = 0; row < tiffData.height; row++)
    for (let column = 0; column < tiffData.width; column++) {
      const index = row * tiffData.width + column

      context.fillStyle = `rgb(${tiffData[0][index]}, ${tiffData[1][index]}, ${tiffData[2][index]})`
      context.fillRect(column, row, 1, 1)
    }

  return canvas
}

export async function getDataLayerMaskCanvas(apiKey, dataLayers) {
  const tiffImageBuffer = await getGeoTiff(apiKey, dataLayers.maskUrl)

  const tiff = await GeoTIFF.fromArrayBuffer(tiffImageBuffer)
  const tiffImage = await tiff.getImage()
  const tiffData = await tiffImage.readRasters()

  const canvas = document.createElement("canvas")

  canvas.width = tiffData.width
  canvas.height = tiffData.height

  const context = canvas.getContext("2d")

  for (let row = 0; row < tiffData.height; row++)
    for (let column = 0; column < tiffData.width; column++) {
      const index = row * tiffData.width + column

      if (tiffData[0][index]) context.fillRect(column, row, 1, 1)
    }

  console.log({ mask: canvas })

  return canvas
}

export async function getDataLayerFluxCanvas(apiKey, dataLayers, scale) {
  const tiffImageBuffer = await getGeoTiff(apiKey, dataLayers.annualFluxUrl)

  const tiff = await GeoTIFF.fromArrayBuffer(tiffImageBuffer)
  const tiffImage = await tiff.getImage()
  const tiffData = await tiffImage.readRasters()

  console.log(tiffData)

  const canvas = document.createElement("canvas")

  canvas.width = tiffData.width * scale
  canvas.height = tiffData.height * scale

  const context = canvas.getContext("2d")

  //const maximumKwhPerKwPerYear = 1000;
  // const minimumKwhPerKwPerYear = Math.max(...tiffData[0]);

  const maximumKwhPerKwPerYear = tiffData[0].reduce(
    (unit, currentUnit) => (unit > currentUnit ? unit : currentUnit),
    0
  )

  for (let row = 0; row < tiffData.height; row += Math.round(1 / scale))
    for (
      let column = 0;
      column < tiffData.width;
      column += Math.round(1 / scale)
    ) {
      const index = row * tiffData.width + column

      const value = tiffData[0][index]

      if (value === -9999) continue

      context.fillStyle = `hsl(50 100% ${(value / maximumKwhPerKwPerYear) *
        100}%)`

      context.fillRect(column * scale, row * scale, 1, 1)
    }

  console.log({ flux: canvas })

  return canvas
}

export async function getDataLayerMonthlyFluxCanvases(
  apiKey,
  dataLayers,
  scale
) {
  const tiffImageBuffer = await getGeoTiff(apiKey, dataLayers.monthlyFluxUrl)

  const tiff = await GeoTIFF.fromArrayBuffer(tiffImageBuffer)
  const tiffImage = await tiff.getImage()
  const tiffData = await tiffImage.readRasters()

  console.log(tiffData)

  //const maximumKwhPerKwPerYear = 1000;
  // const minimumKwhPerKwPerYear = Math.max(...tiffData[0]);

  var canvases = []

  for (var band = 0; band < 12; band++) {
    const canvas = document.createElement("canvas")
    canvas.width = tiffData.width * scale
    canvas.height = tiffData.height * scale
    const context = canvas.getContext("2d")

    const maximumKwhPerKwPerYear = tiffData[band].reduce(
      (unit, currentUnit) => (unit > currentUnit ? unit : currentUnit),
      0
    )

    for (let row = 0; row < tiffData.height; row += Math.round(1 / scale))
      for (
        let column = 0;
        column < tiffData.width;
        column += Math.round(1 / scale)
      ) {
        const index = row * tiffData.width + column

        const value = tiffData[band][index]

        if (value === -9999) continue

        context.fillStyle = `hsl(50 100% ${(value / maximumKwhPerKwPerYear) *
          100}%)`

        context.fillRect(column * scale, row * scale, 1, 1)
      }

    canvases.push(canvas)
  }

  return canvases
}

export async function getDataLayerDsmCanvas(apiKey, dataLayers, scale) {
  const tiffImageBuffer = await getGeoTiff(apiKey, dataLayers.dsmUrl)

  const tiff = await GeoTIFF.fromArrayBuffer(tiffImageBuffer)
  const tiffImage = await tiff.getImage()
  const tiffData = await tiffImage.readRasters()

  console.log(tiffData, "DSM TIFF DATA")

  const canvas = document.createElement("canvas")

  canvas.width = tiffData.width * scale
  canvas.height = tiffData.height * scale

  const context = canvas.getContext("2d")

  //const maximumKwhPerKwPerYear = 1000;
  // const minimumKwhPerKwPerYear = Math.max(...tiffData[0]);

  const maximumKwhPerKwPerYear = tiffData[0].reduce(
    (unit, currentUnit) => (unit > currentUnit ? unit : currentUnit),
    0
  )

  for (let row = 0; row < tiffData.height; row += Math.round(1 / scale))
    for (
      let column = 0;
      column < tiffData.width;
      column += Math.round(1 / scale)
    ) {
      const index = row * tiffData.width + column

      const value = tiffData[0][index]

      if (value === -9999) continue

      context.fillStyle = `hsl(${parseInt(
        190 - (value / maximumKwhPerKwPerYear) * 190
      )} 100% 50%)`

      context.fillRect(column * scale, row * scale, 1, 1)
    }

  console.log({ flux: canvas })

  return canvas
}

export async function getDataLayersCanvas(apiKey, dataLayers) {
  const canvas = document.createElement("canvas")

  const expectedSize = 2000
  const scale = 1
  const size = expectedSize * scale

  canvas.width = size
  canvas.height = size

  const context = canvas.getContext("2d")
  const canvases = await Promise.all([
    getDataLayerFluxCanvas(apiKey, dataLayers, 1),
    getDataLayerMaskCanvas(apiKey, dataLayers),
    getDataLayerDsmCanvas(apiKey, dataLayers, 1)
  ])

  context.drawImage(
    canvases[0],
    0,
    0,
    canvases[0].width,
    canvases[0].height,
    0,
    0,
    size,
    size
  )

  context.globalCompositeOperation = "destination-in"
  context.drawImage(
    canvases[1],
    0,
    0,
    canvases[1].width,
    canvases[1].height,
    0,
    0,
    size,
    size
  )

  // console.log({ result: canvas });

  context.globalCompositeOperation = "destination-over"
  context.drawImage(
    canvases[2],
    0,
    0,
    canvases[2].width,
    canvases[2].height,
    0,
    0,
    size,
    size
  )

  return canvas
}

export async function getDsmCanvas(apiKey, dataLayers) {
  const canvas = createCanvas()

  const context = canvas.getContext("2d")
  const canvases = await Promise.all([
    getDataLayerDsmCanvas(apiKey, dataLayers, 1)
  ])

  context.drawImage(
    canvases[0],
    0,
    0,
    canvases[0].width,
    canvases[0].height,
    0,
    0,
    size,
    size
  )

  return canvas
}

export async function getRgbCanvas(apiKey, dataLayers) {
  const canvas = createCanvas()

  const context = canvas.getContext("2d")
  const canvases = await Promise.all([
    getDataLayerRgbCanvas(apiKey, dataLayers, 1)
  ])

  context.drawImage(
    canvases[0],
    0,
    0,
    canvases[0].width,
    canvases[0].height,
    0,
    0,
    size,
    size
  )

  return canvas
}

export async function getMonthlyFluxCanvases(apiKey, dataLayers) {
  const images = await getDataLayerMonthlyFluxCanvases(apiKey, dataLayers, 1)
  var canvases = []

  for (var i = 0; i < images.length; i++) {
    const canvas = createCanvas()
    const context = canvas.getContext("2d")

    context.drawImage(
      images[i],
      0,
      0,
      images[i].width,
      images[i].height,
      0,
      0,
      size,
      size
    )

    canvases.push(canvas)
  }

  return canvases
}

function createCanvas() {
  const canvas = document.createElement("canvas")

  canvas.width = size
  canvas.height = size

  return canvas
}
