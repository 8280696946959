import Chart from 'chart.js/auto';

 const initElectricityProductionGraph = () => {
   const canvas = document.getElementById('electricityProductionGraph')
   const ctx    = canvas.getContext('2d')
   var gradient = ctx.createLinearGradient(0, 0, 0, 400)
   gradient.addColorStop(0.9, 'rgba(28, 187, 63, 0.09)')
   gradient.addColorStop(0, 'rgba(28, 187, 63, 0.48)')
   const xText = canvas.getAttribute('data-x-text')
   const yText = canvas.getAttribute('data-y-text')

   const data = JSON.parse(canvas.dataset.chart)
   const config = {
     maintainAspectRatio: false,
     type: 'bar',
     data: data,
     options: {
       backgroundColor: gradient,
       scales: {
         y: {
           title: {
            text: yText,
            display: true,
            font: {
              weight: 600
            },
           }
         },
         x: {
           title: {
            text: xText,
            display: true,
            font: {
              weight: 600
            },
           }
         }
       }
     }
   }

   new Chart(canvas, config)
 }

 export default initElectricityProductionGraph;
