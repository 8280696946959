import {intlPhoneNumber} from '../custom/intlPhoneNumber';

const simpleFormTelephone = () => {
  const phoneInputs = document.querySelectorAll('.intl-phone');

  phoneInputs.forEach((phoneInput) => {
    const iti = intlPhoneNumber(phoneInput);
    phoneInput.form.addEventListener('submit', () => {
      if (document.getElementById('prefixNumber')) {
        const prefix = document.querySelector('.iti__selected-dial-code').textContent;
        document.getElementById('prefixNumber').value = prefix;
      } else {
        phoneInput.value = iti.getNumber();
      }
    })

  });


}

export default simpleFormTelephone;
