export default function elementCloneOrRemove() {
  $(document).on('click', '[data-clone-element]', function(e) {
    e.preventDefault();
    let attr = $(e.target).attr('data-clone-element');
    let clone = $(attr).last().clone();
    $(attr).last().after(clone);
  });

  $(document).on('click', '[data-remove-element]', function(e) {
    e.preventDefault();
    let attr = $(e.target).attr('data-remove-element');

    if ($(attr).length > 1) {
      $(attr).last().remove();
    }
  });
}