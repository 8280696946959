export default async function initMap() {
  this.mapElement = document.createElement("div")
  this.mapElement.className = "solar-panels-map"
  this.element.append(this.mapElement)

  const { Map } = await google.maps.importLibrary("maps")

  await google.maps.importLibrary("geometry")

  this.map = new Map(this.mapElement, {
    center: {
      lat: 57.623147493770105,
      lng: 11.931981013011718
    },
    mapTypeId: "satellite",
    tilt: 0,
    styles: [
      {
        featureType: "all",
        elementType: "labels",
        stylers: [{ visibility: "off" }]
      }
    ],
    zoom: 20,
    disableDefaultUI: false,
    scaleControl: true,
    streetViewControl: false,
    mapTypeControl: false,
    rotateControl: false
  })
}
