import flatpickr from 'flatpickr';

const pickDate = () => {
  const dateInput = document.getElementById('datepicker');
  const defaultDate = dateInput.getAttribute('data-default-date');

  flatpickr(dateInput, {
    dateFormat: "d/m/Y",
    defaultDate: defaultDate
  });
}

export default pickDate;
