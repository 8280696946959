import getuserdata from './getUserData';
import Rails from '@rails/ujs';

const addressMessages = () => {
  const container = document.querySelector('.form-group');
  const input = document.getElementById('address');
  const span = container.querySelector('span');

  if (!!span) {
    span.remove();
  }

  container.classList.remove('mb-4');
  container.classList.add('mb-0');


  if (getuserdata('missing_number') != undefined) {
    input.insertAdjacentHTML('afterend', `<span class='errors'>Por favor, instroduzca un número de casa y selecciona la dirección de la lista.</span>`)
  } else if (!sessionStorage.getItem('address')) {
    input.insertAdjacentHTML('afterend', `<span class='errors'>Por favor, seleccione una dirección de la lista.</span>`)
  }
}

const checkAddressValidation = (element) => {
  if (addressValidation()) {
    element.removeAttribute('data-marker');
    element.click();
  } else {
    addressMessages();
  }
}

const allowAction = (element) => {
  if (element.getAttribute('data-marker') === null) {
    return false;
  }

  checkAddressValidation(element);
  return true;
}

function handleConfirm(element) {
  if (allowAction(this)) {
    Rails.stopEverything(element);
  }
}

const addressFormat = () => {
  Rails.delegate(document, 'button[data-marker]', 'click', handleConfirm);
}

const restartSessionStorage = (input) => {
  let i = 0;
  input.addEventListener('input', () => {
    // It only deletes the sessionStorage in the first type
    if (i == 0) {
      localStorage.removeItem('address');
      i++;
    }
  })
}

const addressValidation = () => {
  if ((!getuserdata('missing_number') && !!sessionStorage.getItem('address')) || !!getuserdata('streetNumberException')) {
    return true
  }

  return false
}

export default addressFormat;
